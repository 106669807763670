<template>
      <div class="sketchfab full-width full-height">
            <iframe
                  style="border: none;"
                  class="full-width full-height"
                  src="https://sketchfab.com/models/ee94646ed3e74cdcad0a07148da9130c/embed?autostart=1&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_annotations=0&transparent=1&ui_color=47673E"
                  id="api-frame"
                  sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
                  allow="autoplay; xr-spatial-tracking"
                  xr-spatial-tracking
                  execution-while-out-of-viewport
                  execution-while-not-rendered
                  web-share
                  allowfullscreen
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
            ></iframe>
      </div>
</template>

<script>
export default {
      name: "sketchfab-3D",
      mounted() {
            // let sketchfabScript = document.createElement('script')
            // sketchfabScript.setAttribute('src', 'https://static.sketchfab.com/api/sketchfab-viewer-1.12.1.js')
            // document.head.appendChild(sketchfabScript)
            // var iframe = document.getElementById("api-frame");
            // var client = new Sketchfab("1.12.1", iframe);
            // client.init(this.uid, {
            //       success: function onSuccess(api) {
            //             api.start();
            //             api.addEventListener("viewerready", function () {
            //                   // API is ready to use
            //                   // Insert your code here
            //                   console.log("Viewer is ready");
            //             });
            //       },
            //       error: function onError() {
            //             console.log("Viewer error");
            //       },
            //       autostart : 1,
            //       preload : 1,
            //       ui_controls: 0,
            //       ui_help: 0,
            //       ui_stop: 0,
            //       ui_infos: 0,
            //       ui_settings: 0,
            //       ui_fullscreen:1,
            //       ui_watermark: 0,
            //       ui_watermark_link: 0,
            //       transparent:1
            // });

      },
};
</script>

<style lang='scss'>
</style>