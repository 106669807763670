<template>
      <div class="building align-content-start lg:align-items-center full-height full-width">
            <img class="building-description" src="@/assets/images/desktop/svg/pages/building/building-description.svg" alt="building-description">
            <!-- <div class="mb-50 md:mb-30">
            </div> -->
            <div class="sketch-wrapper mb-50 md:mb-30">
                  <Sketchfab class="sketchfab"/>
            </div>
            <div class="d-none sm:d-flex justify-content-center sm:mt-50">
                  <Btn class="drag" title="Drag and spin to rotate the building" size="sm" icon="arrows/swap"/>
            </div>
      </div>
</template>

<script>
// import {getUnitById} from '@api/axios/unit.service';
import Sketchfab from '@cp/building/Building'
import Btn from '@b/button/Button'
export default {
      name: "building",
      components: {Sketchfab, Btn}
};
</script>

<style lang='scss'>

.building {
      position: relative;
      .building-description {
            @media screen and (max-width: 768px) {
                  width: 40%;
            }
            @media screen and (min-width: 1200px) {
                  // width: 130%;
                  // margin-left: 170px;
                  // margin-top: 20px;
                  width: 30%;
                  position: absolute;
                  z-index: 10;
                  top: 120px;
                  left: 50px;
            }
      }

      .sketch-wrapper {
            @media screen and (min-width: 1200px){
                  position: absolute;
            width: 100%;
            height: 90%;
            left: 0%;
            top: -10%;
            z-index: 111;
            background: transparent;
            }
            @media screen and (max-width: 1200px) {
                  display: flex;
                  align-items: flex-end;
            }
      }

      .sketchfab {

            @media screen and (max-width: 1200px) {
                  height: 140%;
            }
            @media screen and (max-width: 576px) {
                  height: 140%;
                  margin-top: 140px;
            }

            .viewer .gui.enabled {
                  display: none !important;
            }
      }
      .drag {
            background: rgba($black, .05);
            color: $white;
            @include toRem(font-size,16);
            padding: 23px 33px;
            font-family: $open-sans;

            &:hover {
                  box-shadow: unset;
            }

            @media screen and (min-width: 1200px) {
                  // @include toRem(font-size,16);
                  // padding: 20px;
                  position: absolute;
                  z-index: 9999;
                  right: 4%;
                  top: 29%;

            }

            .title {
                  font-weight: 100;
                  margin-left: 20px !important;
                  font-family: 'Vaud';
            }
      }
}
</style>